import React, { useReducer, useEffect, useState } from 'react';
import { Heading, Box, Spinner } from '@chakra-ui/react';
import supabase from '../config/supabaseClient';
import styled, { keyframes } from 'styled-components';
import { GlowWrapper, GlowBackground } from './StyledComponents';
import { useNavigate, useParams } from 'react-router-dom';

interface Tip {
  id: number;
  content: string;
  application_id: number;
}

type State = {
  tips: Tip[];
  currentTip: Tip | null;
};

type Action = { type: 'NEXT' } | { type: 'RESET'; payload: Tip[] };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'NEXT':
      const nextTipIndex = state.tips.findIndex((tip) => tip === state.currentTip) + 1;
      if (nextTipIndex >= state.tips.length) {
        return { ...state, currentTip: state.tips[0] };
      } else {
        return { ...state, currentTip: state.tips[nextTipIndex] };
      }
    case 'RESET':
      return { ...state, tips: action.payload, currentTip: action.payload[0] };
    default:
      return state;
  }
};

const LoadingPage: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, { tips: [], currentTip: null });
  const [error, setError] = React.useState<Error | null>(null);
  const navigate = useNavigate();
  const { applicationId} = useParams();
  useEffect(() => {
    const timerId = setInterval(() => {
      dispatch({ type: 'NEXT' });
    }, 1500); // Change tip every 1500 milliseconds

    getRandomTip();

    setTimeout(() => {
      clearInterval(timerId);
    }, 6000); // Set loading time to 6000 milliseconds

    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    const loadingFromUsers = localStorage.getItem('loadingFromUsers');

    const waitForApplicationId = async () => {
      if (!applicationId) {
        // Application ID is not available in the URL yet, wait for it to become available
        setTimeout(waitForApplicationId, 1000); // Retry after 1 second
        return;
      }

      if (loadingFromUsers === 'true') {
        // If the flag is true, listen for changes in the 'needs' table
        const channel = supabase
          .channel('schema-db-changes')
          .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table: 'needs',
            },
            (payload) => {
              console.log('Change received in needs!', payload);
              // Navigate to the 'userneeds' page when a new need is inserted
              navigateToUserNeeds();
            }
          )
          .subscribe();

        // Check if there are existing needs associated with the current application ID
        const checkExistingNeeds = async () => {
          if (!applicationId) {
            throw new Error('Application ID is missing.');
          }

          const { data: existingNeeds, error } = await supabase
            .from('needs')
            .select('*')
            .eq('application_id', applicationId);

          if (error) {
            console.error('Error fetching needs:', error.message);
            return;
          }

          if (existingNeeds && existingNeeds.length > 0) {
            console.log('Fetched needs:', existingNeeds);
            // If needs already exist, navigate to the 'userneeds' page
            navigateToUserNeeds();
          }
        };

        if (applicationId) {
        checkExistingNeeds();
      }
        return () => {
          // Unsubscribe from the Realtime channel when the component unmounts
          channel.unsubscribe();
        };
      } else {
        // If the flag is false, listen for changes in the 'personas' table
        const channel = supabase
          .channel('schema-db-changes')
          .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table: 'personas',
            },
            (payload) => {
              console.log('Change received in personas!', payload);
              // Navigate to the 'users' page when a new persona is inserted
              navigateToUsers(applicationId);
            }
          )
          .subscribe();
          console.log('app id',applicationId);
        // Check if there are existing personas associated with the current application ID
        const checkExistingPersonas = async () => {
          if (!applicationId) {
            throw new Error('Application ID is missing.');
          }

          const { data: existingPersonas, error } = await supabase
            .from('personas')
            .select('*')
            .eq('application_id', applicationId);

          if (error) {
            console.error('Error fetching personas:', error.message);
            return;
          }
          
          

          if (existingPersonas && existingPersonas.length > 0) {
            console.log('Fetched personas:', existingPersonas);
            // If personas already exist, navigate to the 'users' page
            navigateToUsers(applicationId);
          }
        };

        if (applicationId) {
          checkExistingPersonas();
        }

        return () => {
          // Unsubscribe from the Realtime channel when the component unmounts
          channel.unsubscribe();
        };
      }
    };
    waitForApplicationId();
  }, [applicationId]);


  const getRandomTip = async () => {
    try {
      const { data: tipsData, error } = await supabase.from('tips').select('*');

      if (error) {
        console.error('Error fetching tips:', error.message);
        return;
      }

      if (!tipsData) {
        console.log('No tips found.');
        return;
      }

      const tips: Tip[] = tipsData as Tip[];
      dispatch({ type: 'RESET', payload: tips });
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error fetching tips:', error.message);
      } else {
        console.error('An error occurred while fetching tips.');
      }
    }
  };

  // Function to navigate to users page when personas are inserted
  const navigateToUsers = (applicationId : string | any) => {
    // eslint-disable-next-line no-restricted-globals
    if (!applicationId) {
      throw new Error('Application ID is missing.');
    }
    navigate(`/${applicationId}/users`); // Navigate to the users page
  };

  const navigateToUserNeeds = () => {
    if (!applicationId) {
      throw new Error('Application ID is missing.');
    }
    navigate(`/${applicationId}/userneeds`);
  };

  return (
    <Box
      textAlign="center"
      minHeight="100vh"
      p={4}
      pl="3%"
      backgroundColor="white"
      backgroundSize="cover"
      backgroundPosition="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <GlowWrapper>
        <GlowBackground />
        <Box
          width="36px"
          height="36px"
          flexShrink={0}
          borderRadius="20px"
          background="var(--dark-gray, #333)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          zIndex="2"
        >
          <Spinner size="md" boxSize="24px" zIndex={2} />
        </Box>
      </GlowWrapper>

      <Heading
        mt="20px"
        color="#000"
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="700"
        letterSpacing="-0.16px"
      >
        Generating...
      </Heading>
      <Heading
        mt="8px"
        color="var(--light-gray, #ACACAC)"
        fontFamily="Inter"
        fontSize="18px"
        fontWeight="400"
        lineHeight="150%"
      >
        This may take up to 30 seconds
      </Heading>
    </Box>
  );
};

export default LoadingPage;
