import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import supabase from '../config/supabaseClient';
import { useAuth } from './AuthContext';
import '../fonts.css';
import AppCard from './AppCard';
import WebAppButton from './WebAppButton';
import DataAppButton from './DataAppButton';
import SearchBar from './SearchBar';




export interface AppData {
  id: number;
  name: string;
  description: string;
  link: string;
  user_id: string;
  icon: string;
  created_at: string;
  appStub: string;
}

const LandingPage: React.FC = () => {
  const [createdApps, setCreatedApps] = useState<AppData[]>([]);
  const { isLoggedIn, username } = useAuth();
  const [editingAppId, setEditingAppId] = useState<number | null>(null);
  const [newAppName, setNewAppName] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const localUserId = localStorage.getItem('userId'); // Fetch userId from local storage

    if (localUserId) {
      const fetchApps = async () => {
        const { data: apps, error } = await supabase
          .from('applications')
          .select('*')
          .eq('user_id', localUserId);

        if (error) {
          console.error('Error fetching created apps:', error);
        } else if (apps) {
          setCreatedApps(apps as AppData[]);
        }
      };

      fetchApps();
    } else {
      // If the user is not logged in, clear the list of created apps
      setCreatedApps([]);
    }
  }, [isLoggedIn]);

  //make a deleteApp function to delete the application from the database when pressed on the delete icon
  const deleteApp = async (appId: number) => {
    const { error } = await supabase

      .from('applications')
      .delete()
      .eq('id', appId);

    if (error) {
      console.error('Error deleting app:', error);
    } else {
      setCreatedApps((prevApps) => prevApps.filter((app) => app.id !== appId));
    }
  };


  const editAppName = async (appId: number, newName: string) => {
    const { error } = await supabase
      .from('applications')
      .update({ name: newName })
      .eq('id', appId);
    console.log(newName);
    console.log('appId : ', appId);

    if (error) {
      console.error('Error updating app name:', error);
    } else {
      setCreatedApps((prevApps) =>
        prevApps.map((app) => (app.id === appId ? { ...app, name: newName } : app))
      );
      setEditingAppId(null);
      setNewAppName('');
    }
  };

  const filteredApps = createdApps.filter((app) =>
    app.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box

      textAlign="center"
      minHeight="100vh" p={4}
      pl="3%"
      backgroundColor="white"
      backgroundSize="cover"

      backgroundPosition="center"

    >
      {isLoggedIn ? (

        <Box mt={8}>
          <Text
            color="#333"
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            lineHeight="39px"
            letterSpacing="-0.005em"
            textAlign="center"
            mb={2}
          >
            Hi, {username?.slice(0, username.indexOf("@") != -1 ? username.indexOf("@") : username.length)}!
          </Text>
          <Text
            color="var(--light-gray, #ACACAC)"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="27px"
            letterSpacing="0em"
            textAlign="center"
            mb={6}
          >
            What are we building today?
          </Text>
          <Flex justifyContent="center" gap="20px" >{/* Buttons Web & Mobile */}
            <WebAppButton />
            <DataAppButton />
          </Flex>
          {/* Search bar */}
          <Flex justifyContent="center" mt={6}>
      <Box width="640px">
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Box>
    </Flex>


          {/* Cards for Created Apps */}
          <Flex justifyContent="center" flexWrap="wrap" >
            <Flex flexWrap="wrap" maxWidth="640px" gap="20px" mt={6}> {/* This width accommodates 3 cards each 300px wide plus 20px total gap */}
            {filteredApps.map((app) => (
  
    <AppCard
      key={app.id}
      app={app}
      appStub={app.appStub}
      editingAppId={editingAppId}
      newAppName={newAppName}
      setEditingAppId={setEditingAppId}
      setNewAppName={setNewAppName}
      editAppName={editAppName}
      deleteApp={deleteApp}
    />
  
))}

            </Flex>
          </Flex>
        </Box>

      ) : (
        <Box
          bg="white"

          px={10}
          borderRadius={16}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          width="100%"
          maxWidth="1280px"
          margin="0 auto"
          minHeight="calc(100vh - 40px)"
        >
          <Text
            fontSize="3rem"
            marginTop={-40}
            color="black"
            fontFamily="Inter"
            lineHeight="1.2"
            fontWeight="bold"
          >
            Create, and let's create
          </Text>
          <Text mt={4} color="#666666" fontFamily="Inter">
            Let's build something together today !
          </Text>
          <Link as={RouterLink} to="/signup">
            <Button
              bg="#333333"
              color="white"
              fontSize="1.2rem"
              fontWeight="semibold"
              borderRadius="16px"
              w="222px"
              h="50px"
              mt={6}
              fontFamily="inter"
              _hover={{ backgroundColor: '#222222' }}
            >
              Sign Up
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default LandingPage;