import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

interface NavigationButtonsProps {
  onBackClick: () => void;
  onNextClick: () => void;
  isNextDisabled: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ onBackClick, onNextClick, isNextDisabled, }) => {
  return (
    <Flex justifyContent="center" alignItems="center" gap="24px">
      <Button
        width="308px"
        height="52px"
        padding="0px 18px"
        borderRadius="16px"
        gap="10px"
        background="var(--white, #FFF)"
        boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
        color="var(--dark-gray, #333)"
        fontFamily="Inter"
        fontSize="15px"
        fontWeight="600"
        onClick={onBackClick}
        display="flex"
        justifyContent="center"
        alignItems="center"
        _hover={{
          background: '#FDFDFD',
        }}
      >
        Back
      </Button>
      <Button
        width="308px"
        height="52px"
        padding="0px 18px"
        borderRadius="16px"
        gap="10px"
        background="var(--dark-gray, #333)"
        color="var(--white, #FFF)"
        fontFamily="Inter"
        fontSize="15px"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onNextClick}
        _hover={{
          background: ' #1a1a1a',
          boxShadow: 'none',
        }}
        isDisabled={isNextDisabled}
      >
        Next
      </Button>
    </Flex>
  );
};

export default NavigationButtons;