import React, { useEffect, useState } from 'react';
import { Box, Center, Heading, VStack, Link, Button, Img } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Confetti from 'react-confetti';
import { useParams } from 'react-router-dom';
import supabase from '../config/supabaseClient';


const PromptResult: React.FC = () => {
  const [confettiPieces, setConfettiPieces] = useState(300);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [applicationData, setApplicationData] = useState<any | null>(null);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiPieces(0);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const fetchApplicationData = async () => {
      const { data, error } = await supabase
        .from('applications')
        .select('*')
        .eq('id', applicationId)
        .single();

      if (error) {
        console.error('Error fetching application data:', error);
        return;
      }

      if (data) {
        setApplicationData(data);
      }
    };

    fetchApplicationData();
  }, [applicationId]);

  return (
    <Box
      textAlign="center"
      p={4}
      pl="3%"
      pb="170px"
      backgroundColor="white"
      backgroundSize="cover"
      minHeight={`${window.innerHeight}px`}
    >
      <Confetti numberOfPieces={confettiPieces} />
      <Center>
        <VStack>
          <Heading
            color="#000"
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            lineHeight="normal"
            letterSpacing="-0.16px"
          >
            It's ready!
          </Heading>
          <Box
            color="var(--light-gray, #ACACAC)"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="150%"
            textAlign="center"
          >
            It's time to try your app.
          </Box>
        </VStack>
      </Center>
      <Center mt="48px">
      <Box
  width="240px"
  height="298px"
  display="flex"
  flexDirection="column"
  alignItems="center"
  borderRadius="24px"
  background="#FFF"
  boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
>
  {applicationData ? (
    <Box flexShrink={0} width="200px" height="200px" mt="20px">
      <Img
        src={applicationData.icon}
        alt="Application Icon"
        width="100%"
        height="100%"
        background="lightgray"
        borderRadius={16}
      />
    </Box>
  ) : (
    <Box>Loading...</Box>
  )}

  {applicationData ? (
    <Box
    mt="16px"
      color="var(--dark-gray, #333)"
      fontFamily="Inter"
      fontStyle="normal"
      fontSize="15px"
      fontWeight="500"
      lineHeight="150%"
      textAlign="center"
    >
      {applicationData.name}
    </Box>
  ) : null}

  {applicationData ? (
    <Box
      mt="2px"
      color="var(--light-gray, #ACACAC)"
      fontFamily="Inter"
      fontSize="12px"
      fontWeight="500"
      lineHeight="17px"
      textAlign="center"
    >
       Created at: {new Date(applicationData.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false, // Use 24-hour format
              })}
            </Box>
          ) : null}
</Box>

      </Center>
      <Center mt="48px">
      <Button
  display="flex"
  width="540px"
  height="50px"
  padding="0px 18px"
  justifyContent="center"
  alignItems="center"
  gap="10px"
  alignSelf="stretch"
  borderRadius="16px"
  background="var(--dark-gray, #333)"
  color="var(--white, #FFF)"
  fontFamily="Inter"
  fontSize="15px"
  fontWeight="600"
  onClick={() => {
    if (applicationData && applicationData.appStub) {
      const appStub = applicationData.appStub;
      window.open(`https://${appStub}.kogai.app`, '_blank');
    }
  }}
  _hover={{
    background: ' #1a1a1a',
    boxShadow: 'none',
  }}
>
  Open app
</Button>

    </Center>
    <Center mt="40px">
      <Box
        color="var(--gray, #666)"
        textAlign="center"
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="500"
        lineHeight="normal"
      >
        View Github Repository
      </Box>
    </Center>
  </Box>
);
};

export default PromptResult;
