import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faMicrophone, faUpload } from '@fortawesome/free-solid-svg-icons';
import supabase from '../config/supabaseClient';
import { useAuth } from './AuthContext';
import LoadingPage from './LoadingPage';
import { Box, Center, Heading, Input, IconButton, Button, Textarea, Img } from '@chakra-ui/react';
import axios from 'axios';
import { delay } from 'framer-motion';
import { useParams } from 'react-router';
import { adjectives, colors, uniqueNamesGenerator } from 'unique-names-generator';
import rectImg from '../assets/rect.png';
import mic from '../assets/mic.png';
import csv from '../assets/csv.png';
import BACKEND_URL from '../config/backend';


interface Persona {
  id: number;
  icon: string;
  name: string;
}

interface PromptingPageProps {
  setPrompt: (prompt: string) => void;
}

type OutputItem = string; // each item is a string formatted as a URI
type Output = OutputItem[]; // the output is an array of such items


const PromptingPage: React.FC<PromptingPageProps> = ({ setPrompt }) => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  //const [applicationId, setApplicationId] = useState<number | null>(null);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [listening, setListening] = useState(false);
  const [Loading, setLoading] = useState(false);
  const { isLoggedIn, userId } = useAuth();
  const [fetching, setFetching] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  useEffect(() => {
    setPrompt('');

    const storedPrompt = localStorage.getItem('prompt');

    if (storedPrompt && localStorage.getItem('isRefreshing') === 'true') {
      setSearchText(storedPrompt);
      generatePrompt(storedPrompt, navigate);
    }

    const appType = localStorage.getItem('appType');


    return () => {
      // Clear localStorage
      localStorage.removeItem('prompt');
      localStorage.removeItem('isRefreshing');
      localStorage.removeItem('confirmation');
      localStorage.removeItem('applicationId');
    };

  }, [applicationId]);


  const generateIcon = async (prompt: string, applicationId: string) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/${applicationId}/generateIcon`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });

      if (response.ok) {

      } else {
        console.log('Failed to generate image:', response);
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };





  const fetchPersonas = async (applicationId: string | null) => {
    try {
      // Check if the applicationId is provided, otherwise, try to get it from the URL
      if (!applicationId) {
        // eslint-disable-next-line no-restricted-globals
        const currentApplicationId = location.pathname.split('/')[1];
        if (!currentApplicationId) {
          throw new Error('Application ID is missing.');
        }
        applicationId = currentApplicationId;
      }

      const { data: existingPersonas, error } = await supabase
        .from('personas')
        .select('*')
        .eq('application_id', applicationId);

      if (error) {
        console.error('Error fetching personas:', error.message);
        return null;
      }

      if (existingPersonas && existingPersonas.length > 0) {
        console.log('Fetched personas:', existingPersonas);
        //setIsFetched(true);
        if (!fetching) {
          setFetching(true); // Prevent further fetching
          navigate(`/${applicationId}/users`);
        } // Navigate to the next step
      } else {
        const channel = supabase
          .channel('schema-db-changes')
          .on(
            'postgres_changes',
            {
              event: 'INSERT',
              schema: 'public',
              table: 'personas',
            },
            (payload) => {
              console.log('Change received!', payload);
              navigateToUsers();
            }
          )
          .subscribe();
      }
    } catch (error) {
      console.error('Error fetching personas:', error);
      return null;
    }
  };



  //create as simple function to navigate to `/${applicationId}/users` to call it in the fetchPersonas function that takes the applicationId as a parameter
  const navigateToUsers = () => {
    // eslint-disable-next-line no-restricted-globals
    const currentApplicationId = location.pathname.split('/')[1];
    if (!currentApplicationId) {
      throw new Error('Application ID is missing.');
    }
    navigate(`/${currentApplicationId}/users`); // Navigate to the users page
  };



  const generatePrompt = async (prompt: string, navigate: any) => {
    setLoading(true);
    console.log('Generating prompt:', prompt);
    if (!localStorage.getItem('isRefreshing')) {
      localStorage.setItem('prompt', prompt || searchText);
      localStorage.setItem('isRefreshing', 'true');
    }


    try {

      // Slice dictionaries to only include words of 7 or fewer characters
      const shortAdjectives = adjectives.filter((word) => word.length <= 7);
      const shortColors = colors.filter((word) => word.length <= 7);

      // Generate a random name inspired by the prompt using the short dictionaries
      const randomName = uniqueNamesGenerator({
        dictionaries: [shortAdjectives, shortColors, shortAdjectives], // using the shortened dictionaries
        separator: '_',
        length: 3
      });

      console.log('Generated random name:', randomName);

      // 1. Read the prompt and insert it in supabase and fetch back its id(from supabase)
      const { data: insertedAppId, error: insertError } = await supabase
        .from('applications')
        .insert([{ appDescription: searchText, name: randomName, user_id: userId }])
        //select the id field from the inserted application
        .select('id');




      if (insertError) {
        throw insertError;
      }
      console.log('1');
      console.log('insertedApp:', insertedAppId);
      console.log('AppDescription inserted successfullyffffffffffffff');
      console.log('insertedApp[0]:', insertedAppId);

      const applicationId = insertedAppId[0].id;
      console.log('Application ID:', applicationId);

      // Store the application ID in the local storage
      localStorage.setItem('applicationId', applicationId.toString());
      // Redirect to the loading page with the application ID in the URL

      //get the apptype from local storage to use in the request body
      const appType = localStorage.getItem('appType');
      // 2. Send the post request to the backend containing the applicationId fetched from the previous step
      console.log('Sending post request to the backend');

      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      formData.append('mock', JSON.stringify(false));
      formData.append('appType', JSON.stringify(appType));
      console.log('formdata', formData);
      console.log('file', selectedFile);
      const response = await axios.post(
        `${BACKEND_URL}/api/apps/${applicationId}/personas`,
        formData

      );




      // 3. The backend will redirect to a GET page once received
      if (response.status === 200) {
        fetchPersonas(applicationId);
        navigate(`/${applicationId}/loading`);
      }

      const iconUrl = await generateIcon(searchText, applicationId);

      if (iconUrl) {
        console.log('Storing generated image:', iconUrl, userId);

        // Update the application record with the generated icon URL
        const { data: updatedApp, error } = await supabase.from('applications').update([
          { icon: iconUrl },
        ]).eq('id', applicationId); // Use the correct ID field name here

        if (error) {
          console.error('Error storing generated image:', error.message);
        }
      }



    } catch (error) {
      console.error('Error sending prompt to backend:', error);
    }
  };






  const handleGenerateClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (searchText.trim() !== '') {
      generatePrompt(searchText, navigate);
    }
  };



  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      generatePrompt(searchText, navigate);
    }
  };

  const handleMicClick = () => {

    // Creates a new instance of SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    // If the browser supports SpeechRecognition
    if (SpeechRecognition) {
      // Create a new instance of SpeechRecognition
      const recognition = new SpeechRecognition();

      // Sets the language to English
      recognition.lang = 'en-US';
      // Stops listening after the first result
      recognition.interimResults = false;
      // Sets the maximum number of results to 1
      recognition.maxAlternatives = 1;
      // When the recognition gets a result
      // Focus the text area before starting speech recognition
      const textarea = document.getElementById('prompt-textarea');
      if (textarea) {
        textarea.focus();
      }

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log('Transcript:', transcript);
        setSearchText(transcript);

        // Blur the text area after receiving the speech input
        if (textarea) {
          textarea.blur();
        }
      };

      recognition.onstart = () => {
        setListening(true);
      };

      recognition.onend = () => {
        setListening(false);
      };

      recognition.start();
    } else {
      alert('Speech recognition is not supported in your browser.');
    }
  };

  const handleFileUpload = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const fileInput = document.getElementById('file-input');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
    setUploadedFileName(null);
    // Reset input value for re-uploading the same file
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };


  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFileName = e.target.files?.[0]?.name || null;
    setUploadedFileName(selectedFileName);
    setSelectedFile(e.target.files?.[0] || null);
  };

  if (Loading) {
    return <LoadingPage />;
  }

  return (
    <Box
      textAlign="center"
      p={4}
      pl="3%"
      pb="100%"
      backgroundColor="white"
      backgroundSize="cover"
      minHeight={window.innerHeight}
    >
      <Center flexDirection="column" alignItems="center" width="100%">
        <Heading
          fontFamily="Inter"
          fontStyle="normal"
          textAlign="center"
          lineHeight="normal"
          fontSize="32px"
          fontWeight="700"
          letterSpacing="-0.16px"
          color="#000"
        >
          Describe your app
        </Heading>
        <Heading
          as="h2"
          mt="8px"
          textAlign="center"
          fontFamily="Inter"
          fontStyle="normal"
          fontSize="18px"
          fontWeight="400"
          lineHeight="150%"
          color="var(--light-gray, #ACACAC)"
        >
          Give as much detail as you want
        </Heading>

        <Box
          onFocus={() => setIsFieldFocused(true)}
          onBlur={() => setIsFieldFocused(false)}
          mt="42px"
          width="540px"
          height="140px"
          padding="24px"
          borderRadius="24px"
          border="10px solid white"
          boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 11px 11px 0px rgba(0, 0, 0, 0.02), 0px 25px 15px 0px rgba(0, 0, 0, 0.01), 0px 44px 18px 0px rgba(0, 0, 0, 0.00), 0px 69px 19px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)" // Add your desired box-shadow here
          backgroundColor={isFieldFocused ? "white" : "var(--extra-light-gray, #F7F7F7)"} // Case 1 and 3
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          bgColor={
            isFieldFocused
              ? "var(--extra-light-gray, #F7F7F7)" // Case 2 and 4
              : "white" // Case 1 and 3

          }
        >
          <Textarea
            id="prompt-textarea"
            onFocus={() => setIsFieldFocused(true)}
            onBlur={() => setIsFieldFocused(false)}
            width="456px"
            height="92px"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="27px"
            letterSpacing="0em"
            textAlign="left"
            border="none"
            borderRadius="24px"
            bgColor={
              isFieldFocused
                ? "var(--extra-light-gray, #F7F7F7)" // Case 2 and 4
                : "white" // Case 1 and 3

            }
            color={
              isFieldFocused
                ? "var(--dark-gray, #171717)" // Case 2 and 4
                : "var(--dark-gray, #171717)" // Case 1 and 3
            }
            placeholder="example: A cooking app that allows user to share and store recipes"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown}

            _focusVisible={{ borderColor: "#7A7A7A" }}
            resize="none"
          />
          <Box
            display="flex"
            width="36px"
            height="36px"
            padding="6px"
            marginLeft="8px"
            justifyContent="center"
            alignItems="center"
            borderRadius="28px"
            background="white"
            boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.1)" // subtle gray border shadow
            cursor="pointer"
            onClick={handleMicClick}
          >
            <Img src={mic} />
          </Box>
          {localStorage.getItem('appType') === 'data-analysis' && (
            <label htmlFor="file-input">
              <Box
                display="flex"
                width="36px"
                height="36px"
                padding="6px"
                marginLeft="8px"
                justifyContent="center"
                alignItems="center"
                borderRadius="28px"
                background="white"
                boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.1)" // subtle gray border shadow
                cursor={selectedFile ? 'default' : 'pointer'} // Disable cursor if a file is selected
                onClick={(e) => !selectedFile && handleFileUpload(e)}
              >
                <img
                  src={require('../assets/csv.png')}
                  alt="CSV Icon"
                  style={{ width: '20px', height: '20px' }}
                />
              </Box>
            </label>
          )}
          <Input
            id="file-input"
            type="file"
            accept=".csv, .xls, .xlsx"
            style={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: '0',
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0, 0, 0, 0)',
              whiteSpace: 'nowrap',
              border: '0',
            }}
            onChange={handleFileInputChange}
          />
          {uploadedFileName && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '325px',
                zIndex: 1, // Ensure the rectangle appears below other elements
                position: 'absolute', // Add this to make z-index work
                width: '75px',
                height: '15px',
                padding: '3px',
                borderRadius: '16px',
                background: 'white',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.1)', // subtle gray border shadow
                fontSize: '10px',
                fontWeight: '400',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                color: 'var(--dark-gray, #171717)',

              }}>
              {`${uploadedFileName.substring(0, 6)}...`}
              <img
                src={require('../assets/delete.png')}
                alt="Cancel upload"
                style={{ width: '15px', height: '15px', cursor: 'pointer', position: 'absolute', left: '60px' }}
                onClick={handleFileCancel}
              />
            </Box>
          )}
        </Box>


        <Button
          mt={12}
          width="540px"
          padding="16px 0px"
          borderRadius="16px"
          bg="var(--dark-gray, #333)"
          color="white"
          fontSize="1.2rem"
          transition="background 2s cubic-bezier(0.5, 0, 0.2, 1)"
          _hover={{
            background: 'linear-gradient(to right, #FF9559, #6923FF)',
          }}
          onClick={handleGenerateClick}
          isDisabled={searchText.trim() === ''}
          cursor={searchText.trim() === '' ? 'not-allowed' : 'pointer'}
        >
          Generate

        </Button>
      </Center>
    </Box>
  );

};

export default PromptingPage;
