import React, { useState } from 'react';
import supabase from '../config/supabaseClient';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import styles from '../styles/SignIn.module.css';
import logo from '../assets/Kog Logo.png';
import { FaGoogle, FaLinkedin } from 'react-icons/fa';
import { BiHide, BiShow } from 'react-icons/bi';
import axios from 'axios';
import { useAuth } from './AuthContext';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import rectImg from '../assets/rect.png';
import GoogleIcon from '../assets/Google.svg';
import LinkedInIcon from '../assets/Linkedin.svg';
import EmailField from './EmailField';
import PasswordField from './PasswordField';



const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');

    if (!validateEmail(email)) {
      setEmailError('Invalid email.');
      return;
    }

    try {
      await signIn(email, password);
      navigate('/');
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'message' in error) {
        if ((error as Error).message === 'INVALID PASSWORD') {
          setPasswordError('Incorrect password.');
        } else {
          setPasswordError('Incorrect email.');
        }
      }
    }
  };







  return (
    <Center minHeight="100vh" p={4}
    pl="3%"
    backgroundColor="white"
    backgroundSize="cover"
    
    backgroundPosition="center">
      <Box
        display="flex"
        width="480px"
        padding="60px 40px"
        flexDirection="column"
        alignItems="center"
        gap="40px"
        borderRadius="40px"
        background="#FFF"
        boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 11px 11px 0px rgba(0, 0, 0, 0.02), 0px 25px 15px 0px rgba(0, 0, 0, 0.01), 0px 44px 18px 0px rgba(0, 0, 0, 0.00), 0px 69px 19px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
      >
        <img src={logo} alt="Your Logo" width="43px" style={{ flex: '1 0 0' }} />
        <Text
          color="#000"
          textAlign="center"
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          lineHeight="normal"
          letterSpacing="-0.16px"
          mb={4}
        >
          Create and let's create
        </Text>
        <Flex direction="column" gap="30px" width="400px">
          <Flex direction="column" gap="10px" width="400px">
            <Button
              display="flex"
              height="50px"
              padding="0px 18px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              alignSelf="stretch"
              borderRadius="16px"
              background="var(--white, #FFF)"
              boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              onClick={() => { /* Handle Google Sign In */ }}
            >
              <img src={GoogleIcon} alt="Google" width="16px" height="16px" />
              <Text
                color="var(--dark-gray, #171717)"
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="500"
                lineHeight="150%" // 22.5px
              >
                Continue with Google
              </Text>
            </Button>
            <Button
              display="flex"
              height="50px"
              padding="0px 18px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              alignSelf="stretch"
              borderRadius="16px"
              background="var(--white, #FFF)"
              boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              onClick={() => { /* Handle LinkedIn Sign In */ }}
            >
              <img src={LinkedInIcon} alt="LinkedIn" width="16px" height="16px" />
              <Text
                color="var(--dark-gray, #171717)"
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="500"
                lineHeight="150%" // 22.5px
              >
                Continue with LinkedIn
              </Text>
            </Button>
          </Flex>
          <Text
            alignSelf="stretch"
            width="400px"
            color="var(--dark-gray, #171717)"
            textAlign="center"
            fontFamily="Inter"
            fontSize="15px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="150%" // 22.5px

          >
            Or
          </Text>

          <form onSubmit={handleSignIn}>
            <Stack spacing={4}>
            <EmailField value={email} onChange={setEmail} error={emailError} />

            <PasswordField value={password} onChange={setPassword} error={passwordError} />
              <Button
                display="flex"
                height="50px"
                padding="0px 18px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                alignSelf="stretch"
                borderRadius="16px"
                background="var(--dark-gray, #333)"
                type='submit'
                _hover={{
                  background: ' #1a1a1a',
                }}
              >
                <Text
                  color="var(--white, #FFF)"
                  textAlign="center"
                  fontFamily="Inter"
                  fontSize="15px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="normal"
                >
                  Sign in
                </Text>
              </Button>
            </Stack>
          </form>
          <Text
            alignSelf="stretch"
            color="var(--dark-gray, #333)"
            textAlign="center"
            fontFamily="Inter"
            fontSize="15px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="150%" // 22.5px
          >
            <a href="/reset-password" style={{ textDecoration: 'none', color: 'inherit' }}>Reset password</a>
          </Text>

          <Flex mt={4} justifyContent="center" alignItems="center">
            <Text
              alignSelf="stretch"
              color="var(--light-gray, #ACACAC)"
              textAlign="center"
              fontFamily="Inter"
              fontSize="15px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="150%" // 22.5px
            >
              Don't have an account?{' '}
              <Link as={RouterLink} to="/signup">
                <span style={{
                  color: 'var(--dark-gray, #333)',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '150%', // 22.5px
                  textDecoration: 'none',
                }}>
                  Signup
                </span>
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Center>
  );
};

export default SignIn;
