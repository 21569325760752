import React from 'react';
import { FormControl, Input, InputGroup, InputRightElement, IconButton, Text } from '@chakra-ui/react';
import { BiHide, BiShow } from 'react-icons/bi';

interface PasswordFieldProps {
  value: string;
  onChange: (newValue: string) => void;
  error: string | null;
}

const PasswordField: React.FC<PasswordFieldProps> = ({ value, onChange, error }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <FormControl isRequired>
    <InputGroup display="flex" alignItems="center" justifyContent="center"> 
      <Input
        display="flex"
        height="50px"
        padding="0px 18px"
        alignItems="center"
        alignSelf="stretch"
        borderRadius="16px"
        background="var(--white, #FFF)"
        boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        color={'var(--dark-gray, #333)'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <InputRightElement style={{ marginTop: '5px' }}>
        <IconButton
          display="flex" alignItems="center" justifyContent="center"
          icon={showPassword ? <BiHide /> : <BiShow />}
          color={'#1E1E1E'}
          onClick={() => setShowPassword(!showPassword)}
          variant="unstyled"
          aria-label={''}
        />
      </InputRightElement>
    </InputGroup>
    {error && <Text color="red">{error}</Text>}
  </FormControl>
);
};

export default PasswordField;
