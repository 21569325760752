import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Spinner, Text } from '@chakra-ui/react';
import checkIcon from '../assets/checkIcon.png';
import { GlowWrapper, GlowBackground } from './StyledComponents';
import supabase from '../config/supabaseClient';

const FinalLoading: React.FC = () => {
  const [stepStates, setStepStates] = useState(['spinner', 'gray', 'gray', 'gray']);
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const customStepNames = [
    'Specifying data transformations',
    'Generating metrics and visualizations code',
    'Analyzing charts',
    'Deploying in the cloud',
  ];


  useEffect(() => {
    const fetchInitialStatus = async () => {
      const { data, error } = await supabase
        .from('applications')
        .select('status')
        .eq('id', applicationId)
        .single();
  
      if (error) {
        console.error('Error fetching initial status:', error);
        return;
      }
  
      const initialStatus = data?.status;
  
      // Mapping from statuses to step indices
      const statusesToIndices = {
        'specifying': 0,
        'computing': 1,
        'analyzing': 2,
        'publishing': 3,
        'published': 4,
      };
  
      if (initialStatus && Object.keys(statusesToIndices).includes(initialStatus)) {
        const initialStep = statusesToIndices[initialStatus as keyof typeof statusesToIndices];
        const newStepStates = Array(4).fill('gray').map((_, index) => {
          if (index < initialStep) {
            return 'checked';
          }
          if (index === initialStep && initialStatus !== 'published') {
            return 'spinner'; // Start spinning this step
          }
          return 'gray';
        });
  
        setStepStates(newStepStates);
  
        // If the last status is 'published', navigate directly
        if (initialStatus === 'published') {
          navigate(`/${applicationId}/prompt_result`);
        }
      } else {
        // Handle null or undefined status
        if (initialStatus == null || initialStatus === 'specifying') {
          setStepStates(['spinner', 'gray', 'gray', 'gray']);
        }
      }
    };
  
    fetchInitialStatus();
  }, [applicationId]);


  useEffect(() => {
    // Step 1: Mapping out navigation
  if (stepStates[0] === 'spinner') {
    const channel1 = supabase
      .channel(`applications:id=eq.${applicationId}`)
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'applications',
        filter: 'status=eq.computing',  // Updated Status Name
      }, (payload) => {
        console.log('Change received!', payload);
        setStepStates(prev => [...prev.slice(0, 0), 'checked', 'spinner', ...prev.slice(2)]);
      })
      .subscribe();
  }

  // Step 2: Defining functional elements
  if (stepStates[1] === 'spinner') {
    const channel2 = supabase
      .channel(`applications:id=eq.${applicationId}`)
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'applications',
        filter: 'status=eq.analyzing',  // Updated Status Name
      }, (payload) => {
        console.log('Change received for step 2!', payload);
        setStepStates(prev => [...prev.slice(0, 1), 'checked', 'spinner', ...prev.slice(3)]);
      })
      .subscribe();
  }

  // Step 3: Setting up database model
  if (stepStates[2] === 'spinner') {
    const channel3 = supabase
      .channel(`applications:id=eq.${applicationId}`)
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'applications',
        filter: 'status=eq.publishing',  // Updated Status Name
      }, (payload) => {
        console.log('Change received for step 3!', payload);
        setStepStates(prev => [...prev.slice(0, 2), 'checked', 'spinner', ...prev.slice(4)]);
      })
      .subscribe();
  }

  // Step 4: Deploying in the cloud
  if (stepStates[3] === 'spinner') {
    const channel4 = supabase
      .channel(`applications:id=eq.${applicationId}`)
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'applications',
        filter: 'status=eq.published',  // Updated Status Name
      }, (payload) => {
        console.log('Change received for final step!', payload);
        setStepStates(prev => [...prev.slice(0, 3), 'checked']);
        navigate(`/${applicationId}/prompt_result`);
      })
      .subscribe();
  }
}, [applicationId, navigate, stepStates]);
  

  return (
    <Box
      textAlign="center"
      minHeight="100vh" p={4}
    pl="3%"
    backgroundColor="white"
    backgroundSize="cover"
    
    backgroundPosition="center"

    >
      <Box
        display="flex"
        width="640px"
        flexDirection="column"
        alignItems="center"
        gap="8px"
        textAlign="center"
        backgroundColor="white"
        margin="auto"
      >
        <Text

          color="#000"
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          letterSpacing="-0.16px"
        >
          Your app is being generated
        </Text>
        <Text
          color="var(--light-gray, #ACACAC)"
          fontFamily="Inter"
          fontSize="18px"
          fontWeight="400"
          lineHeight="150%"
        >
          Be patient, it will take time.
        </Text>
      </Box>
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="flex-start"
        width="261px"
        height="280px"
        mt={16}
      >
        {stepStates.map((state, index) => (
          <React.Fragment key={index}>
            <Box
              display="flex"
              alignItems="center"
              width="150%"
              gap="16px"
            >
              {state === 'gray' && (
                <Box
                  width="36px"
                  height="36px"
                  flexShrink={0}
                  borderRadius="20px"
                  background="none"
                  border="3px solid var(--Light-Gray, #ACACAC)"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  
                ></Box>
              )}
              {state === 'spinner' && (
                <GlowWrapper>
                  <GlowBackground />
                  <Box
                    width="36px"
                    height="36px"
                    flexShrink={0}
                    borderRadius="20px"
                    background="var(--dark-gray, #333)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    zIndex="3"
                  >
                    <Spinner size="md" boxSize="24px" />
                  </Box>
                </GlowWrapper>
              )}
              {state === 'checked' && (
                <Box
                  width="36px"
                  height="36px"
                  flexShrink={0}
                  borderRadius="20px"
                  background="var(--dark-gray, #333)"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                >
                  <img src={checkIcon} alt="Check Icon" width="24px" height="24px" />
                </Box>
              )}

              <Text
                display="flex"
                alignItems="left"
                width="100%"
                justifyContent="flex-start"
                textAlign="left"
                color={
                  state === 'gray'
                    ? 'var(--Light-Gray, #ACACAC)'
                    : 'var(--Dark-Gray, #333)'
                }
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="600"
                lineHeight="normal"
              >
                {customStepNames[index]}
              </Text>
            </Box>

            {index < 3 && (
              <Box
              
                width="3px"
                height="20px"
                background={
                  state === 'checked'
                    ? 'var(--Dark-Gray, #333)'
                    : 'var(--Light-Gray, #ACACAC)'
                }
                marginLeft="18px"
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default FinalLoading;