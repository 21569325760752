import React from 'react';
import { Box, Button } from '@chakra-ui/react';

interface LogoutButtonProps {
  onLogoutClick: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogoutClick }) => {
  return (
    <Box
      display="flex"
      width="140px"
      padding="10px 16px"
      alignItems="flex-start"
      borderRadius="12px"
      background="var(--white, #FFF)"
      boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 11px 11px 0px rgba(0, 0, 0, 0.02), 0px 25px 15px 0px rgba(0, 0, 0, 0.01), 0px 44px 18px 0px rgba(0, 0, 0, 0.00), 0px 69px 19px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
    >
      <Button
        color="#000"
        fontFamily="Inter"
        fontSize="15px"
        fontWeight="400"
        lineHeight="150%"
        onClick={onLogoutClick}
      >
        Log out
      </Button>
    </Box>
  );
};

export default LogoutButton;
