import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  Avatar,
  VStack,
  HStack,
  Text,
  Checkbox,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  Button,
  Center
} from '@chakra-ui/react';
import { AddIcon, EditIcon, CheckIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import supabase from '../config/supabaseClient';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import check from '../assets/check.png';
import add from '../assets/add.png';
import NavigationButtons from './NavigationButtons';
import FeatureCard from './FeatureCard';
import axios from 'axios';
import BACKEND_URL from '../config/backend';

interface UserNeedsProps {
  selectedPersonas: string[];
  prompt: string;
}
interface Need {
  id: number;
  persona_id: number;
  desc: string;
}

interface GroupedNeeds {
  [key: string]: {
    icon: string;
    needs: Need[];
  };
}

const UserNeeds: React.FC<UserNeedsProps> = ({ selectedPersonas, prompt }) => {
  const [needsData, setNeedsData] = useState<any[]>([]);
  const [personasData, setPersonasData] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { applicationId } = useParams<{ applicationId: string }>();
  const [editingNeedIndex, setEditingNeedIndex] = useState<{ [key: number]: number | null }>({});
  const [addingNeedIndex, setAddingNeedIndex] = useState<{ [key: number]: number | null }>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [generalPersonaId, setGeneralPersonaId] = useState<number | null>(null);
  const [checkedNeeds, setCheckedNeeds] = useState<number[]>([]);
  const [isAddingNeed, setIsAddingNeed] = useState(false);
  const [isAddFeatureClicked, setIsAddFeatureClicked] = useState(false);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);



  useEffect(() => {
    const fetchGeneralPersonaId = async () => {
      const { data: personas } = await supabase
        .from('personas')
        .select('id')
        .eq('name', 'general')
        .eq('application_id', applicationId) // Add this line to filter by applicationId
        .limit(1);

      if (personas && personas.length > 0) {
        setGeneralPersonaId(personas[0].id);
      }
    };

    fetchGeneralPersonaId();
  }, [applicationId]);

  useEffect(() => {
    const fetchPersonas = async () => {
      const { data: personas } = await supabase.from('personas').select('*');
      if (personas) setPersonasData(personas);
    };

    fetchPersonas();
  }, []);

  useEffect(() => {
    if (generalPersonaId) {
      const selectedPersonaIds = location.state?.selectedPersonaIds || [];
      const fetchNeeds = async (selectedPersonaIds: number[]) => {
        // Modify the query to include the applicationId
        try {
          console.log('Fetching needs for persona IDs:', selectedPersonaIds);
          console.log('Fetching needs for application ID:', applicationId);
          console.log('Fetching needs for general persona ID:', generalPersonaId);
          console.log('Fetching needs for name personas', personasData);
          const { data: needs } = await supabase
            .from('needs')
            .select('*')
            .in('persona_id', [...selectedPersonaIds, generalPersonaId]);

          console.log('fetched needs: ', needs)
          if (needs) setNeedsData(needs);
        } catch (error) {
          console.error('Error fetching needs:', error);
        }
      };

      fetchNeeds(selectedPersonaIds);
    }
  }, [location.state, generalPersonaId, applicationId]);


  const handleCheckToggle = (needId: number) => {
    setCheckedNeeds((prevCheckedNeeds) => {
      if (prevCheckedNeeds.includes(needId)) {
        return prevCheckedNeeds.filter((id) => id !== needId);
      } else {
        return [...prevCheckedNeeds, needId];
      }
    });
  };
  const isChecked = (needId: number): boolean => {
    return checkedNeeds.includes(needId);

  };


  const fetchUpdatedNeeds = async () => {
    if (generalPersonaId) {
      const selectedPersonaIds = location.state?.selectedPersonaIds || [];
      const { data: needs, error } = await supabase
        .from('needs')
        .select('*')
        .in('persona_id', [...selectedPersonaIds, generalPersonaId]);

      if (error) {
        console.error('Error fetching updated needs:', error);
      }

      if (needs) {
        setNeedsData(needs);
      }
    }
  };

  const addNeedToList = (newNeed: any) => {
    // Clone the existing needs data
    const updatedNeedsData = [...needsData];

    // Add the new need at the beginning of the array
    updatedNeedsData.unshift(newNeed);

    // Update the state with the new array
    setNeedsData(updatedNeedsData);
  };
 

  const addNeedInServer = async (newNeed: any) => {
    // Include the applicationId in the newNeed object
    newNeed.application_id = applicationId;
    console.log("app id", applicationId)
  
    // Add need in the database
    const { data: need, error } = await supabase.from('needs').insert(newNeed);
    if (error) {
      console.error('Error inserting need:', error);
    }
    
    if (need) {
      //make the new inserted need in the first position of the needsData array and shift all of the other existing needs into the next position so the last inserted need would be first
      
    }

  };
  useEffect(() => {
    console.log('needsData:', needsData); // Add this line to log the array
  }, [needsData]);


  const handleStartAddingNeed = () => {
    setIsAddingNeed(true);
    setInputValue(''); // Resetting input value
  };

  const handleCancelAddingNeed = () => {
    setIsAddingNeed(false);
  };


  const handleSaveNewNeed = async () => {
    const newNeed = {
      desc: inputValue,
      persona_id: generalPersonaId,
    };

    await addNeedInServer(newNeed);
    setIsAddingNeed(false);

    // Fetch updated needs and set them in state
    fetchUpdatedNeeds();
  };

  const handleSaveEditedNeed = async (needId: string, updatedDesc: string, personaId: string) => {
    const editedNeed = {
      desc: updatedDesc,
      persona_id: personaId,
    };

    await editNeedInServer(needId, updatedDesc, personaId); // Call your existing edit function

    // Fetch updated needs and set them in state
    fetchUpdatedNeeds();
  };



  const editNeedInServer = async (needId: string, updatedDesc: string, personaId: string) => {
    // Complete the function for editing the selected need
    const { data: need, error } = await supabase
      .from('needs')
      .update({ desc: updatedDesc })
      .eq('id', needId)
      .eq('persona_id', personaId)
      .single();

    if (error) {
      console.error('Error updating need:', error);
    }
    if (need) {
      setInputValue('');
      setEditingNeedIndex({ ...editingNeedIndex, [needId]: null });

      // Fetch updated needs and set them in state
      await fetchUpdatedNeeds();
    }
  };





  const getPersonaData = (personaId: number) => {
    return personasData.find((persona) => persona.id === personaId);
  };

  const groupedNeeds = React.useMemo(() => {

    return needsData.reduce((acc, need) => {
      const personaData = getPersonaData(need.persona_id);
      if (personaData) {
        if (!acc[personaData.name]) {
          acc[personaData.name] = { needs: [], icon: personaData.icon };
        }
        acc[personaData.name].needs.push(need);
      }
      return acc;
    }, {} as { [key: string]: { needs: any[]; icon: string } });
  }, [needsData]);

  const handleBackClick = () => {
    navigate(`/${applicationId}/users`);
  };
  const handleNextClick = async () => {
    // Define the URL for the POST request
    
    const appType = localStorage.getItem('appType');
    try {
      // Send a POST request without a request body
      const response = await axios.post(
        `${BACKEND_URL}/api/apps/${applicationId}/pipeline`,
        {
          appType: appType,
        }
      );
      
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Handle success, e.g., navigate to the next step
        navigate(`/${applicationId}/finalloading`);
      } else {
        // Handle any other status codes or errors
        console.error('Failed to move to the next step:', response);
      }
    } catch (error) {
      // Handle any network or request errors
      console.error('Error moving to the next step:', error);
    }
  };

  useEffect(() => {
    // Calculate the total content height
    const totalContentHeight = mainContainerRef.current?.scrollHeight || 0;

    // Add an extra 100px
    const newHeight = totalContentHeight + 300;

    // Set the height of the main container
    if (mainContainerRef.current) {
      mainContainerRef.current.style.height = `${newHeight}px`;
    }
  }, [needsData, checkedNeeds]);



  return (
    <Box>
      <Box
ref={mainContainerRef}
        textAlign="center"
        minHeight="100vh" p={4}
        pl="3%"
        backgroundColor="white"
        backgroundSize="cover"

        backgroundPosition="center"

      >
        {/* <Box textAlign="center" marginTop={4}> */}
        <Heading color="#000" fontFamily="Inter" fontSize="32px" fontWeight="700" letterSpacing="-0.16px">
          Features.
        </Heading>
        <Text
          color="#ACACAC"
          fontFamily="Inter"
          fontSize="18px"
          fontWeight="400"
          lineHeight="27px"
          marginTop={2}
        >
          What should users be able to do?
        </Text>
        {/* </Box> */}
        <Box mx="auto" maxWidth="640px">
          <VStack spacing={4} marginTop={12}>
            {isAddingNeed ? (
              <Box width="640px" height="64px" flex="1 0 0">
                {/* Container matching the card style */}
                <Box
                  display="flex"
                  height="64px"
                  padding="20px 20px 20px 24px"
                  alignItems="center"
                  alignSelf="stretch"
                  gap="16px"
                  borderRadius="24px"
                  background="#FDFDFD"
                  boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
                  color="var(--dark-gray, #333)"
                >
                  <Box
                    display="flex"
                    paddingRight="0px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="5px"
                    background="#FFF"
                  >
                  </Box>
                  <InputGroup size="md">
                    <Input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="New Need"
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                        background: 'var(--white, #FFF)',
                        padding: '0px',
                        fontSize: '15px',
                        fontWeight: '400',
                        lineHeight: '22.5px',
                        fontFamily: 'Inter',
                        color: 'var(--dark-gray, #333)', // Set text color
                      }}
                    />
                    <InputRightElement width="auto">
                      <Button
                        display="flex"
                        width="60px"
                        height="25px"
                        padding="4px 10px"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="8px"
                        background="var(--white, #FFF)"
                        boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="500"
                        lineHeight="17px"
                        textAlign="center"
                        color="var(--dark-gray, #333)"
                        onClick={handleCancelAddingNeed}
                        marginRight="8px"
                      >
                        Cancel
                      </Button>
                      <Button
                        display="flex"
                        width="48px"
                        height="25px"
                        padding="4px 10px"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="8px"
                        background="var(--dark-gray, #333)"
                        boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="500"
                        lineHeight="17px"
                        textAlign="center"
                        color="var(--white, #FFF)"
                        onClick={handleSaveNewNeed}
                        isDisabled={!inputValue}
                      >
                        Save
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </Box>
            ) : (
              <Box
                onClick={handleStartAddingNeed}
                display="flex"
                width="640px"
                height="74px"
                padding="20px 20px 20px 24px"
                alignItems="center"
                gap="16px"
                flexShrink={0}
                borderRadius="24px"
                border="2px dashed #E9E9E9"
                background="#FDFDFD"
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="400"
                lineHeight="22.5px"
                color="var(--light-gray, #ACACAC)" // Change text color
                cursor="pointer" // Change cursor style
                _hover={{ background: '#F4F4F4' }} // Add hover effect
              >
                <img src={add} alt="Add Icon" />
                <Text>Add feature...</Text>
              </Box>
            )}

{needsData.slice().reverse().map((need) => (
<FeatureCard
    key={need.id}
    need={need}
    isChecked={isChecked(need.id)}
    onCheckToggle={handleCheckToggle}
    onEdit={handleSaveEditedNeed}
  />
))}





          </VStack>



          <Box position="fixed" bottom="0px" left="0"  // Align to the left edge
            right="0" display="flex" justifyContent="center" width="100vw" >
            <Box
              display="flex"
              width="100%"
              height="152px"
              padding="0px 10px 80px 30px"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flexShrink={0}
              background="white"
            >
              <NavigationButtons onBackClick={handleBackClick} onNextClick={handleNextClick} isNextDisabled={needsData.length === 0 || needsData.length === checkedNeeds.length} />

            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

  );

};

export default UserNeeds;
