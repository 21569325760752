import React, { useState, useRef, useEffect } from 'react';
import supabase from '../config/supabaseClient';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import styles from '../styles/SignUp.module.css';
import logo from '../assets/Kog Logo.png';
import { FaGoogle, FaLinkedin } from 'react-icons/fa';
import { BiHide, BiShow } from 'react-icons/bi';
import axios from 'axios';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { color } from 'framer-motion';
import rectImg from '../assets/rect.png';
import GoogleIcon from '../assets/Google.svg';
import LinkedInIcon from '../assets/Linkedin.svg';
import EmailField from './EmailField'; 
import PasswordField from './PasswordField'; 
import { useAuth } from './AuthContext';


const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [showErrorBubble, setShowErrorBubble] = useState(false);
  const errorBubbleRef = useRef<HTMLDivElement | null>(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const { signIn } = useAuth();

  
  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validatePassword = (password: string) => {
    const lengthRequirement = /.{8,}$/;
    const uppercaseRequirement = /(?=.*[A-Z])/;
    const lowercaseRequirement = /(?=.*[a-z])/;
    const digitRequirement = /(?=.*\d)/;
    const symbolRequirement = /(?=.*[@$!%*#?&])/;

    const errors = [];

    if (!lengthRequirement.test(password)) {
      errors.push('Your password must be at least 8 characters long.');
    }
    if (!uppercaseRequirement.test(password)) {
      errors.push('Your password must contain at least 1 uppercase letter.');
    }
    if (!lowercaseRequirement.test(password)) {
      errors.push('Your password must contain at least 1 lowercase letter.');
    }
    if (!digitRequirement.test(password)) {
      errors.push('Your password must contain at least 1 digit.');
    }
    if (!symbolRequirement.test(password)) {
      errors.push('Your password must contain at least 1 special character (@, $, !, %, *, #, ?, &).');
    }

    return errors;
  };

  const handleInputBlur = () => {
    const newErrors = validatePassword(password);
    setPasswordErrors(newErrors);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (errorBubbleRef.current && !errorBubbleRef.current.contains(event.target as Node)) {
      setShowErrorBubble(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    const emailValidation = validateEmail(email);
    const passwordErrors = validatePassword(password);
  
    if (!emailValidation || passwordErrors.length > 0 || password !== passwordConfirmation) {
      let errorMessage = '';

      if (!emailValidation) {
        errorMessage += 'Invalid email.\n';
      }

      if (passwordErrors.length > 0) {
        errorMessage += passwordErrors.join('\n');
      }

      if (password !== passwordConfirmation) {
        setPasswordMatchError("Passwords don't match.");
        errorMessage += "Passwords don't match.\n";
      } else {
        setPasswordMatchError('');
      }

      setPasswordErrors(passwordErrors);
      console.log('Validation error:', errorMessage);
      return;
    }
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });
  
      if (error) {
        console.error('Error creating account:', error);
        alert('Error creating account. Please try again.');
        return;
      }
  
      if (data) {
        await signIn( email, password );
        navigate('/'); 
      }
    } catch (error) {
      console.error('Exception:', error);
      alert('Error creating account. Please try again.');
    }
  };
  

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };




  return (
    <Center minHeight="100vh" p={4}
      pl="3%"
      backgroundColor="white"
      backgroundSize="cover"

      backgroundPosition="center">
      <Box
        display="flex"
        width="480px"
        padding="60px 40px"
        flexDirection="column"
        alignItems="center"
        gap="40px"
        borderRadius="40px"
        background="#FFF"
        boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 11px 11px 0px rgba(0, 0, 0, 0.02), 0px 25px 15px 0px rgba(0, 0, 0, 0.01), 0px 44px 18px 0px rgba(0, 0, 0, 0.00), 0px 69px 19px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
      >
        <img src={logo} alt="Your Logo" width="43px" style={{ flex: '1 0 0' }} />
        <Text
          color="#000"
          textAlign="center"
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          lineHeight="normal"
          letterSpacing="-0.16px"
          mb={4}
        >
          Create and let's create
        </Text>
        <Flex direction="column" gap="30px" width="400px">
          <Flex direction="column" gap="10px" width="400px">
            <Button
              display="flex"
              height="50px"
              padding="0px 18px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              alignSelf="stretch"
              borderRadius="16px"
              background="var(--white, #FFF)"
              boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              onClick={() => { /* Handle Google Sign Up */ }}
            >
              <img src={GoogleIcon} alt="Google" width="16px" height="16px" />
              <Text
                color="var(--dark-gray, #171717)"
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="500"
                lineHeight="150%" // 22.5px
              >
                Sign Up with Google
              </Text>
            </Button>
            <Button
              display="flex"
              height="50px"
              padding="0px 18px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              alignSelf="stretch"
              borderRadius="16px"
              background="var(--white, #FFF)"
              boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              onClick={() => { /* Handle LinkedIn Sign Up */ }}

            >
              <img src={LinkedInIcon} alt="LinkedIn" width="16px" height="16px" />
              <Text
                color="var(--dark-gray, #171717)"
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="500"
                lineHeight="150%" // 22.5px
              >
                Sign Up with LinkedIn
              </Text>
            </Button>
          </Flex>
          <Text
            alignSelf="stretch"
            width="400px"
            color="var(--dark-gray, #171717)"
            textAlign="center"
            fontFamily="Inter"
            fontSize="15px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="150%" // 22.5px
          >
            Or
          </Text>

          <form onSubmit={handleSignUp}>
  <Stack spacing={4}>
    <EmailField value={email} onChange={(e) => setEmail(e)} error={''} /> 
    <PasswordField value={password} onChange={(e) => setPassword(e)} error={''} /> 
    <PasswordField
      value={passwordConfirmation}
      onChange={(e) => setPasswordConfirmation(e)}
      error={''}
    />

{passwordErrors.length > 0 && (
  <Box
    ref={errorBubbleRef}
    backgroundColor="#f9d4d4"
    p={2}
    borderRadius="md"
    boxShadow="md"
  >
    {passwordErrors.map((error) => (
      <Text key={error} color="red" fontSize="sm">
        {error}
      </Text>
    ))}
  </Box>
)}
{passwordMatchError && (
  <Box backgroundColor="#f9d4d4" p={2} borderRadius="md" boxShadow="md">
    <Text color="red" fontSize="sm">
      {passwordMatchError}
    </Text>
  </Box>
)}


    <Button
      type="submit"
      display="flex"
      height="50px"
      padding="0px 18px"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      alignSelf="stretch"
      borderRadius="16px"
      background="var(--dark-gray, #333)"
      _hover={{
        background: ' #1a1a1a',
      }}
    >
      <Text
        color="var(--white, #FFF)"
        fontFamily="Inter"
        fontSize="15px"
        fontWeight="500"
        lineHeight="150%" // 22.5px
      >
        Create Account
      </Text>
    </Button>
    <Text
      alignSelf="stretch"
      color="var(--light-gray, #ACACAC)"
      textAlign="center"
      fontFamily="Inter"
      fontSize="15px"
      fontStyle="normal"
      fontWeight="500"
      lineHeight="150%" // 22.5px
    >
      Already have an account?{' '}
      <Link as={RouterLink} to="/signin" style={{ color: 'var(--dark-gray, #333)', textDecoration: 'none' }}>
        <span style={{ fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '500', lineHeight: '150%' }}>
          Sign in
        </span>
      </Link>
    </Text>
  </Stack>
</form>
        </Flex>
      </Box>
    </Center>
  );


};

export default SignUp;