import React, { useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  Textarea,
} from '@chakra-ui/react';
import check from '../assets/check.png';
import add from '../assets/add.png';

interface FeatureCardProps {
  need: any;
  isChecked: boolean;
  onCheckToggle: (needId: number) => void;
  onEdit: (needId: string, updatedDesc: string, personaId: string) => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  need,
  isChecked,
  onCheckToggle,
  onEdit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(need.desc);

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setInputValue(need.desc);
  };

  const handleSaveEdit = () => {
    const updatedDesc = inputValue.trim();
    if (updatedDesc !== '') {
      onEdit(need.id.toString(), updatedDesc, need.persona_id.toString()); // Pass personaId
      setIsEditing(false);
    }
  };

  const truncatedDesc = need.desc.length > 195 ? `${need.desc.slice(0, 180)}...` : need.desc;
  const textToDisplay = isEditing ? inputValue : truncatedDesc;
  const inputHeight = isEditing ? '66px' : need.desc.length > 195 ? '66px' : '46px';
  return (
    <Box
      onClick={() => {
        if (!isEditing) {
          onCheckToggle(need.id);
        }
      }}
      style={{ cursor: 'pointer' }}
      width="640px"
      height="94px"
      flex="1 0 0"
      key={need.id}
    >
      <Box
        display="flex"
        height="94px"
        padding="20px 20px 20px 24px"
        alignItems="center"
        alignSelf="stretch"
        gap="16px"
        borderRadius="24px"
        background={isChecked ? '#FDFDFD' : '#FFF'}
        boxShadow={isChecked ? 'none' : '0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}
        border={isChecked ? '1px solid #E9E9E9' : 'none'}
        color={isChecked ? 'var(--light-gray, #ACACAC)' : 'var(--dark-gray, #333)'}
        _hover={{
          '.edit-button': { opacity: 1 },
        }}
      >
        <Box
          display="flex"
          paddingRight="0px"
          justifyContent="center"
          alignItems="center"
          borderRadius="5px"
          background="#FFF"
          onClick={() => onCheckToggle(need.id)}
        >
          {isChecked ? (
            <img src={add} alt="Add Icon" />
          ) : (
            <img src={check} alt="Check Icon" />
          )}
        </Box>

        {isEditing ? (
          <InputGroup size="md"  >
            <Textarea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={truncatedDesc}
            height={need.desc.length > 190 ? '66px' : '46px'}
            style={{
              border: 'none',
              boxShadow: 'none',
              background: 'var(--white, #FFF)',
              padding: '0px',
              fontSize: '15px',
              fontWeight: '400',
              lineHeight: '22.5px',
              fontFamily: 'Inter',
              resize: 'none',
              width: '80%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          />
            <InputRightElement width="auto">
              <Button
                display="flex"
                width="60px"
                height="25px"
                padding="4px 10px"
                justifyContent="center"
                alignItems="center"
                borderRadius="8px"
                background="var(--white, #FFF)"
                boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="500"
                lineHeight="17px"
                textAlign="center"
                color="var(--dark-gray, #333)"
                onClick={handleCancelEdit}
                marginRight="8px"
                mt={10}
                position="relative"
              >
                Cancel
              </Button>
              <Button
                display="flex"
                width="48px"
                height="25px"
                padding="4px 10px"
                justifyContent="center"
                alignItems="center"
                borderRadius="8px"
                background="var(--dark-gray, #333)"
                boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="500"
                lineHeight="17px"
                textAlign="center"
                color="var(--white, #FFF)"
                onClick={handleSaveEdit}
                mt={10}
                position="relative"
                _hover={{
                  background: ' #1a1a1a',
                  boxShadow: 'none',
                }}
              >
                Save
              </Button>
            </InputRightElement>
          </InputGroup>
        ) : (
          <>
            <Text
              display="flex"
              alignSelf="flex-start"
              textAlign="left"
              width="100px" // Adjust the width to accommodate buttons
              alignItems="center"
              fontFamily="Inter"
              fontSize="15px"
              fontWeight="400"
              lineHeight="22.5px"
              title={need.desc} // Prevent line breaks
              height={need.desc.length > 190 ? '66px' : '46px'}
              style={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                //whiteSpace: 'nowrap',
              }}
            >
              {truncatedDesc}
            </Text>

            <Button
              className="edit-button"
              display="flex"
              alignItems="center"
              width="43px"
              height="25px"
              padding="4px 10px"
              borderRadius="8px"
              opacity={0}
              background="var(--white, #FFF)"
              boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="500"
              lineHeight="17px"
              letterSpacing="0em"
              textAlign="center"
              onClick={(e) => handleEditClick(e)}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FeatureCard;
