import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer style={{ backgroundColor: 'white' }}>
    </footer>
  );
};

export default Footer;
