import styled, { keyframes } from 'styled-components';

const glowAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 0.7;
  }
  25%, 75% {
    transform: scale(1.2);
    opacity: 1;
  }
`;

export const GlowWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

export const GlowBackground = styled.div`
  position: absolute;
  z-index: 1;
  width: 120%;
  height: 120%;
  border-radius: 50%;
  background: conic-gradient(from 0deg, #ffaa77, #aa77ff);
  filter: blur(10px);
  animation: ${glowAnimation} 2s linear infinite;
`;
