import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Image, Button, Text } from '@chakra-ui/react';
import logo from '../assets/Kog Logo.png';
import { useAuth } from './AuthContext';
import user from '../assets/user.png';
import LogoutButton from './LogoutButton';


interface HeaderProps {
  prompt: string;
  showPrompt: boolean;
}

const Header: React.FC<HeaderProps> = ({ prompt, showPrompt }) => {
  const { isLoggedIn, username, signOut } = useAuth();
  const [showLogout, setShowLogout] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  const handleLogout = () => {
    signOut();
   
    localStorage.clear(); // Clear local storage
    sessionStorage.clear(); // Clear session storage
  
    navigate('/');
  
  };

  const isLandingPage = location.pathname === '/';

  return (
    <Box as="header" p={4} bg="white" height="82px">
      <Flex justify="space-between" alignItems="center">
        <Box mr="1.5%">
          <Image src={logo} alt="Your Logo" width="60px" ml="55%" />
        </Box>
        {isLoggedIn ? (
          <Flex alignItems="center">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              position="relative"
              height="60px" // Assuming that 60px is enough to contain the avatar and logout button.
            >
              <Box
                display="flex"
                width="40px"
                height="40px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="26px"
                background="#000"
                onClick={() => setShowLogout(!showLogout)}
                cursor="pointer"
                flexShrink={0}
              >
                <Image src={user} alt="User Avatar" />
              </Box>
              {showLogout && (
                <Box position="absolute" top="50px" right="0"> {/* Adjust positioning based on your needs */}
                  <LogoutButton onLogoutClick={handleLogout} />
                </Box>
              )}
            </Box>
          </Flex>
        ) : (
          <Flex alignItems="center">
          <Link to="/signin">
            <Button
              borderRadius="8px"
              bg="var(--white, #FFF)"
              boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
              color="var(--dark-gray, #333)"
              fontSize="12px"
              fontWeight="500"
              fontFamily="Inter"
              textAlign="center"
              px={4}
              py={2}
              lineHeight="17px"
              _hover={{ bg: '#E8E8E8' }}
              mr="1rem"
            >
              Log in
            </Button>
          </Link>
          <Link to="/signup">
            <Button
              borderRadius="8px"
              bg="var(--dark-gray, #333)"
              color="var(--white, #FFF)"
              fontSize="12px"
              fontWeight={500}
              lineHeight="17px"
              px={10}
              py={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              _hover={{ bg: '#222' }}
            >
              Sign up
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  </Box>
);
};

export default Header;
