import React from 'react';
import { Link as RouterLink, useNavigate, useLocation  } from 'react-router-dom';
import { Button, Image, Text, Link } from '@chakra-ui/react';
import mobileAppIcon from '../assets/chart.png';

const DataAppButton: React.FC = () => {

  return (
    <Link as={RouterLink} to="/prompting">
      <Button
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        width="310px"
        height="160px"
        padding="44px 0px 43px 0px"
        borderRadius="16px"
        gap="10px"
        background="linear-gradient(135deg, #FF62D3 12.29%, #6923FF 100%)"
        boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.07), 0px 4px 8px 0px rgba(0, 0, 0, 0.07), 0px 15px 15px 0px rgba(0, 0, 0, 0.06), 0px 33px 20px 0px rgba(0, 0, 0, 0.03), 0px 59px 23px 0px rgba(0, 0, 0, 0.01), 0px 91px 26px 0px rgba(0, 0, 0, 0.00)"
        _hover={{
          background: 'linear-gradient(135deg, #FF62D3 8%, #6923FF 90%)',
        }}
        onClick={() => localStorage.setItem('appType', 'data-analysis')}
      >
        <Image src={mobileAppIcon} alt="Data Analysis App" width="40px" height="40px" />
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="600"
          lineHeight="normal"
        >
          Data Analysis App
        </Text>
      </Button>
    </Link>
  );
};

export default DataAppButton;