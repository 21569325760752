import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Box, Heading, Button, Image, Text, SimpleGrid, IconButton, useColorModeValue, Flex } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@chakra-ui/icons';
import NavigationButtons from './NavigationButtons';
import supabase from '../config/supabaseClient';
import LoadingPage from './LoadingPage';
import axios from 'axios';
import rectImg from '../assets/rect.png';
import check from '../assets/check.png';
import add from '../assets/add.png';
import BACKEND_URL from '../config/backend';

interface Persona {
  id: number;
  icon: string;
  name: string;
}

const Users: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const prompt = (location.state as { prompt: string } | undefined)?.prompt || '';
  //get the const applicationId from the URL
  const { applicationId } = useParams();
  const [usersList, setUsersList] = useState<Persona[]>([]);
  const [checkedUsers, setCheckedUsers] =
    useState(
      new Set(JSON.parse(localStorage.getItem('checkedUsers') || '[]'))
    );
  const [Loading, setLoading] = useState(false);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const storedCheckedUsers = localStorage.getItem('checkedUsers');
    if (!storedCheckedUsers || JSON.parse(storedCheckedUsers).length === 0) {
      const allUsersChecked = new Set(usersList.map(user => user.id));
      setCheckedUsers(allUsersChecked);
      localStorage.setItem('checkedUsers', JSON.stringify(Array.from(allUsersChecked)));
    } else {
      setCheckedUsers(new Set(JSON.parse(storedCheckedUsers)));
    }

    const unloadHandler = () => {
      localStorage.removeItem('checkedUsers');
    };

    window.addEventListener('beforeunload', unloadHandler);


    return () => {
      // Clear localStorage
      localStorage.removeItem('checkedUsers');
      localStorage.removeItem('confirmationNeeds');
      window.removeEventListener('beforeunload', unloadHandler); //on window close
    };

  }, [usersList]);




  useEffect(() => {
    const checkedUsersArray = Array.from(checkedUsers);
    localStorage.setItem('checkedUsers', JSON.stringify(checkedUsersArray));

    if (mainContainerRef.current) {
      const totalContentHeight = mainContainerRef.current.scrollHeight;
      const newHeight = totalContentHeight + 300;
      mainContainerRef.current.style.height = `${newHeight}px`;
    }
  }, [checkedUsers]);

  useEffect(() => {
    const fetchPersonas = async () => {
      if (!applicationId) {
        console.error('Application ID is missing.');
        return;
      }
      try {
        console.log('Fetching personas for application ID:', applicationId);
        const { data: personas, error } = await supabase
          .from('personas')
          .select('*')
          .eq('application_id', applicationId);

        if (error) {
          console.error('Error fetching personas:', error.message);
          return;
        }

        if (!personas || personas.length === 0) {
          console.log('No personas found.');
          return;
        }

        const filteredPersonas = (personas as Persona[]).filter(
          (persona: Persona) => persona.name.toLowerCase() !== 'general'
        );

        setUsersList(filteredPersonas);
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };

    if (applicationId) {
      fetchPersonas();
    }
  }, [applicationId]);



  const handleCheckToggle = (id: number) => {
    const updatedCheckedUsers = new Set(checkedUsers);
    if (updatedCheckedUsers.has(id)) {
      updatedCheckedUsers.delete(id);
    } else {
      updatedCheckedUsers.add(id);
    }
    setCheckedUsers(updatedCheckedUsers);
  };

  const handleBackClick = () => {
    navigate(`/prompting`);
  };

  const isChecked = (id: number) => checkedUsers.has(id);
  const btnBg = useColorModeValue('gray.200', 'gray.700');

  const handleNextClick = async () => {
    setLoading(true);
  
    try {
      const selectedPersonas = Array.from(checkedUsers)
        .map((id) => usersList.find((user) => user.id === id))
        .filter((p): p is Persona => !!p);
  
      if (selectedPersonas.length === 0) {
        // No personas selected, return without doing anything
        setLoading(false);
        return;
      }
  
      const selectedPersonaIds = selectedPersonas.map((p) => p.id);
      const appType = localStorage.getItem('appType');
      console.log('Sending selected persona ids to backend:', selectedPersonaIds);
  
      const response = await axios.post(
        `${BACKEND_URL}/api/apps/${applicationId}/needs`,
        {
          personaIds: selectedPersonaIds,
          mock: false,
          appType: appType,
        }
      );
  
      if (response.status === 200) {
        // Set a flag in local storage to indicate that this loading is from the Users page
        localStorage.setItem('loadingFromUsers', 'true');
        // Navigate to the loading page
        navigate(`/${applicationId}/loading`);
      } else {
        setLoading(false);
      }
    } catch (error: unknown) {
      console.error('Error:', error);
    }
  };





  return (
    <Box
    ref={mainContainerRef}
      textAlign="center"
      minHeight="100vh" p={4}
    pl="3%"
    backgroundColor="white"
    backgroundSize="cover"
    
    backgroundPosition="center"

    >
      <Text
        color="#000"
        textAlign="center"
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="700"
        letterSpacing="-0.16px"
      >
        We found the perfect users!
      </Text>
      <Text
        color="var(--light-gray, #ACACAC)"
        textAlign="center"
        fontFamily="Inter"
        fontSize="18px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="150%" // 27px
      >
        Decide who will be the users
      </Text>
      
      <Box mx="auto" maxWidth="640px">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing="24px" // Gap between two cards
          mx="auto" // Center the container
          maxWidth="640px" // 2 * 308px (cards) + 24px (gap) + 2 * 116px (side padding)
        >
          {usersList.map((user: Persona) => (
            <Box // Wrap the entire card content in a clickable container
              key={user.id}
              onClick={() => handleCheckToggle(user.id)} // Attach the click handler to the container
              style={{
                cursor: 'pointer', // Show pointer cursor on hover
              }}
            >
              <Flex
                key={user.id}
                display="flex"
                width="308px" // Fill
                height="auto" // Hug
                padding="20px"
                borderRadius="24px"
                alignItems="center"
                gap="16px"
                style={{
                  border: isChecked(user.id) ? "2px solid #BDBDBD" : "2px dashed #E9E9E9", // Adjusted border
                  background: isChecked(user.id) ? "#FFF" : "#FDFDFD",
                }}
              >
                <Flex
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="48px"
                  height="48px"
                  textAlign="center"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "32px",
                    fontWeight: 500,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  {user.icon.startsWith('&#') ? (
                    <Text dangerouslySetInnerHTML={{ __html: user.icon }} opacity={isChecked(user.id) ? 1 : 0.5} />
                  ) : (
                    <Image
                      src={user.icon}
                      alt={user.name}
                      boxSize="48px"
                      objectFit="cover"
                      opacity={isChecked(user.id) ? 1 : 0.5}
                      filter={isChecked(user.id) ? "none" : "grayscale(100%)"}
                    />
                  )}
                </Flex>
                <Text
                  width="172px"
                  height="23px"
                  color="#000"
                  fontFamily="Inter"
                  fontSize="15px"
                  fontWeight="500"
                  lineHeight="23px"
                  letterSpacing="0px"
                  textAlign="left"
                  opacity={isChecked(user.id) ? 1 : 0.5}
                  filter={isChecked(user.id) ? "none" : "grayscale(100%)"}
                >
                  {user.name}
                </Text>
                <Box
                  width="20px"
                  height="20px"
                  padding="0px 0.07664099335670471px 0px 0px"
                  borderRadius="5px"
                  onClick={() => handleCheckToggle(user.id)}
                >
                  <Image src={isChecked(user.id) ? check : add} alt={isChecked(user.id) ? "Checked" : "Add"} />
                </Box>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>


        <Box position="fixed" bottom="0px" display="flex" justifyContent="center">
          <Box
            display="flex"
            width="100%"
            height="152px"
            padding="0px 10px 80px 0px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexShrink={0}
            background="white"
          >
            <NavigationButtons onBackClick={handleBackClick} onNextClick={handleNextClick} isNextDisabled={Array.from(checkedUsers).length === 0} />

          </Box>
        </Box>
      </Box>
    </Box>

  );

};

export default Users;
