import React from 'react';
import { FormControl, Input, Text } from '@chakra-ui/react';

interface EmailFieldProps {
  value: string;
  onChange: (newValue: string) => void;
  error: string | null;
}

const EmailField: React.FC<EmailFieldProps> = ({ value, onChange, error }) => {
  return (
    <FormControl isRequired>
      <Input
        display="flex"
        width="400px"
        height="50px"
        padding="0px 18px"
        alignItems="center"
        alignSelf="stretch"
        borderRadius="16px"
        border="6px solid var(--white, #FFF)"
        background="var(--extra-light-gray, #F7F7F7)"
        boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
        type="email"
        placeholder="Email"
        color={'var(--dark-gray, #333)'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {error && <Text color="red">{error}</Text>}
    </FormControl>
  );
};

export default EmailField;
