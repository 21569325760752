import React, { useState } from 'react';
import {
  Box,
  Image,
  Input,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { AppData } from './LandingPage';

interface AppCardProps {
  app: AppData;
  appStub: string;
  editingAppId: number | null;
  newAppName: string;
  setEditingAppId: (appId: number | null) => void;
  setNewAppName: (newName: string) => void;
  editAppName: (appId: number, newName: string) => void;
  deleteApp: (appId: number) => void;
}

const AppCard: React.FC<AppCardProps> = ({
  app,
  appStub,
  editingAppId,
  newAppName,
  setEditingAppId,
  setNewAppName,
  editAppName,
  deleteApp,
}) => {


  const handleCardClick = () => {
    if (appStub) {
      window.open(`https://${appStub}.kogai.app`, '_blank');
    }
  };

  const handleButtonClick = (e: React.MouseEvent, callback: () => void) => {
    e.stopPropagation(); 
    callback();
  };


  return (
    <Box
      key={app.id}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      bg="white"
      borderRadius="16px"
      //padding="16px"
      width="200px"
      height="258"
      //boxShadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
      flexShrink={0}
      flexGrow={0}
      marginBottom="16px"
      position="relative"
      _hover={{
        ".app-card-icons": {
          display: "flex",
        },
      }}
      onClick={handleCardClick}
    >
      <Image src={app.icon} alt={app.name} width="200px" height="200px" borderRadius="16px" />
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap="2px" mt={2}>
        {editingAppId === app.id ? (
          <Input
            value={newAppName}
            onChange={(e) => setNewAppName(e.target.value)}
            placeholder="Enter new name"
            color={'var(--dark-gray, #333)'}
          />
        ) : (
          <Text
            color="var(--dark-gray, #333)"
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="15px"
            fontWeight={500}
            lineHeight="150%"
          >
            {app.name}
          </Text>
        )}
        <Text
          color="var(--light-gray, #ACACAC)"
          fontFamily="Inter"
          fontSize="12px"
          fontWeight={500}
          lineHeight="17px"
        >
          Created on {new Date(app.created_at).toLocaleDateString()}
        </Text>
      </Box>
      <Box
        display={editingAppId === app.id ? "flex" : "none"}
        alignItems="center"
        justifyContent="flex-end"
        position="absolute"
        top="8px"
        right="8px"
        gap="5px"
        className="app-card-icons"
      >
        {editingAppId === app.id ? (
          <>
            <IconButton
              aria-label="Confirm Edit"
              icon={<CheckIcon />}
              borderRadius="8px"
              bg="var(--white, #FFF)"
              _hover={{ bg: 'var(--light-gray, #ACACAC)' }}
              onClick={(e) => handleButtonClick(e, () => {
                if (newAppName.trim() !== '') {
                  editAppName(app.id, newAppName);
                }
              })}
            />
            <IconButton
              aria-label="Cancel Edit"
              icon={<CloseIcon />}
              borderRadius="8px"
              bg="var(--white, #FFF)"
              _hover={{ bg: 'var(--light-gray, #ACACAC)' }}
              onClick={(e) => handleButtonClick(e, () => {
                setEditingAppId(null);
                setNewAppName('');
              })}
            />
          </>
        ) : (
          <>
            <IconButton
              aria-label="Edit"
              icon={<EditIcon />}
              borderRadius="8px"
              bg="var(--white, #FFF)"
              _hover={{ bg: 'var(--light-gray, #ACACAC)' }}
              onClick={(e) => handleButtonClick(e, () => {
                setEditingAppId(app.id);
                setNewAppName(app.name);
              })}
            />
            <IconButton
              aria-label="Delete"
              icon={<DeleteIcon />}
              borderRadius="8px"
              bg="var(--white, #FFF)"
              _hover={{ bg: 'var(--light-gray, #ACACAC)' }}
              onClick={(e) => handleButtonClick(e, () => deleteApp(app.id))}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AppCard;
