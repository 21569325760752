import React from 'react';
import { Box, Input, Icon } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchQuery, setSearchQuery }) => {
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box
      borderRadius="12px"
      bg="var(--Extra-Light-Gray, #F7F7F7)"
      p="12px"
      display="flex"
      alignItems="center"
      gap="10px"
      alignSelf="stretch"
      width="640px"
      height="48px"
    >
      <Icon as={SearchIcon} color="var(--dark-gray, #333)" />
      
      <Box 
      
        flex="1"
        borderRadius="12px"
        bg="var(--Extra-Light-Gray, #F7F7F7)"
        px={3}
      >
        <Input
          type="text"
          ml="-35px"
          placeholder="Search apps"
          color={'var(--dark-gray, #333)'}
          value={searchQuery}
          onChange={handleSearchChange}
          border="none"
          boxShadow="none"
          bg="transparent"
          _focus={{ borderColor: 'transparent', boxShadow: "none" }}
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
