import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, Text, Image, Link } from '@chakra-ui/react';
import webAppIcon from '../assets/WebApp.png';

const WebAppButton: React.FC = () => {

    return (
        <Link
            as={RouterLink}
            to="/prompting"
        >
            <Button
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                width="310px"
                height="160px"
                padding="44px 0px 43px 0px"
                borderRadius="16px"
                gap="10px"
                background="linear-gradient(135deg, #FFD234 18.83%, #FF5823 86.46%)"
                boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.07), 0px 4px 8px 0px rgba(0, 0, 0, 0.07), 0px 15px 15px 0px rgba(0, 0, 0, 0.06), 0px 33px 20px 0px rgba(0, 0, 0, 0.03), 0px 59px 23px 0px rgba(0, 0, 0, 0.01), 0px 91px 26px 0px rgba(0, 0, 0, 0.00)"
                _hover={{
                    background: 'linear-gradient(135deg, #FFD234 10%, #FF5823 80%)',
                }}
                onClick={() => localStorage.setItem('appType', 'generic')}
            >
                <Image src={webAppIcon} alt="Web App" width="40px" height="40px" />
                <Text
                    color="#FFF"
                    textAlign="center"
                    fontFamily="Inter"
                    fontSize="19px"
                    fontWeight="600"
                    lineHeight="normal"
                >
                    Web App
                </Text>
            </Button>
        </Link>
    );
};

export default WebAppButton;
